import googlePlayImage from '../../assets/googleplay.png';
import appleStoreImage from '../../assets/applestore.png';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';

export default function Footer () {
    return (
        <footer className='mt-20 mb-5'>
            <div className="flex flex-col justify-center items-center text-wrap gap-5">
                
                <div className="flex flex-col lg:flex-row gap-10">

                    <div className="flex flex-col gap-5 text-center">
                        <h3 className='text-green text-lg xl:text-xl font-bold'>para parceiros</h3>
                        <div className="flex flex-col xxs:flex-row gap-5">

                            <a href="https://play.google.com/store/apps/details?id=com.zumbiverde.barbuddybrasil" target="_blank" rel="noopener noreferrer">
                                <img src={googlePlayImage} alt="Google Play" className="w-36 xl:w-40"/>
                            </a>

                            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                                <img src={appleStoreImage} alt="App Store" className="w-36 xl:w-40" />
                            </a>
                            
                        </div>
                    </div>

                    <div className="flex flex-col gap-5 text-center">
                        <h3 className='text-green text-lg xl:text-xl font-bold'>para diversão</h3>
                        <div className="flex flex-col xxs:flex-row gap-5">

                            <a href="https://play.google.com/store/apps/details?id=com.zumbiverde.barbuddybrasil" target="_blank" rel="noopener noreferrer">
                                <img src={googlePlayImage} alt="Google Play" className="w-36 xl:w-40"/>
                            </a>

                            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                                <img src={appleStoreImage} alt="App Store" className="w-36 xl:w-40" />
                            </a>

                        </div>
                    </div>

                </div>

                <div className="flex gap-8">
                    <a href="https://www.instagram.com/budd_app/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Google Play" className="w-6 xl:w-8"/>
                    </a>

                    <a href="https://www.linkedin.com/company/budd-app/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="App Store" className="w-6 xl:w-8" />
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=559892175906" target="_blank" rel="noopener noreferrer">
                        <img src={whatsapp} alt="WhatsApp" className="w-6 xl:w-8" />
                    </a>
                </div>

            </div>

            <p className="text-sm xl:text-lg text-center mt-4">
                budd © todos os direitos reservados.
            </p>
        </footer>
    )
}