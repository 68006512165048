import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import ricardo from "../../assets/ricardo.png"
import matheus from "../../assets/matheus.png"
import michael from "../../assets/michael.jpg"

const Weare = () => {

  return (
    <div className="app">
      
      <Header />
      
      <div className="grow px-8 md:px-20 3xl:px-40 text-justify leading-5">
        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6'>Quem somos</h2>
        
        <p className='mt-2 text-lg md:text-xl font-medium'>Na <span className='text-green'>budd</span> brasil, nossa missão é proporcionar 
        uma experiência excepcional aos nossos usuários. Queremos simplificar sua vida, 
        tornando mais fácil encontrar os 
        melhores locais, eventos e muito mais, com base em suas preferências e localização.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Nossa História</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Fundada em 2024, a <span className='text-green'>budd</span> brasil 
        começou como uma ideia para melhorar a forma como as pessoas exploram e 
        se conectam com o mundo ao seu redor. Ao longo do ano, crescemos e evoluímos, 
        sempre com o compromisso de fornecer as
        melhores soluções para nossos usuários.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Nossa Equipe</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Nosso time é composto por pessoas apaixonadas por 
        tecnologia e inovação. Trabalhamos incansavelmente para criar e melhorar nosso 
        aplicativo, ouvindo o feedback de 
        nossos usuários e buscando constantemente maneiras de aprimorar sua experiência.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Como Podemos Ajudar</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Estamos aqui para tornar sua vida mais fácil. 
        Se você está procurando lugares incríveis para visitar, eventos emocionantes 
        para participar ou apenas deseja explorar sua cidade, o <span className='text-green'>budd</span> está aqui para ajudar.
        Nosso compromisso é proporcionar a você as 
        informações de que você precisa, quando mais precisa.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Fale Conosco</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Queremos ouvir você! Se você tiver alguma pergunta, 
        sugestão ou apenas quiser entrar em contato, não hesite em nos enviar uma
        mensagem. Estamos ansiosos para ouvir sua opinião e continuar melhorando.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Contato</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Em caso de dúvidas, por favor entre em contato conosco através do e-mail: <span className='text-green'>ricardosilvanet03@gmail.com</span></p>
      </div>

      <div className="bg-white mt-24 relative z-0 after:hidden md:after:block after:content-[''] after:absolute after:w-full after:h-10 after:bg-dgreen after:left-0 after:top-[calc(100%/1.9)] after:z-1 after:shadow-md after:hidden">
          
          <div className="py-24">
              <h2 className='text-dgreen mb-10 text-2xl md:text-3xl font-bold text-center'>Conheça nosso time</h2>

              <div className="flex flex-col md:flex-row justify-center items-center gap-12 relative z-10">
                  <ProfileCard
                    photo={ricardo}
                    name='Ricardo Silva'
                    charge='ceo | dev'
                    description='Programador Full-Stack'
                    gitHub='https://github.com/ricardozv'
                    linkedin='https://www.linkedin.com/in/ricardo-silva-b11ba215a/'
                  />

                  <ProfileCard
                    photo={michael}
                    name='Michael Cardoso'
                    charge='co-founder | dev'
                    description='Antropólo programador'
                    gitHub='https://github.com/michaeljmcardoso'
                    linkedin='https://www.linkedin.com/in/michael-cardoso-84a9a0b2'
                  />

                  <ProfileCard
                    photo={matheus}
                    name='Matheus Garcia'
                    charge='dev'
                    description='Programador Front-End Web e Mobile'
                    gitHub='https://github.com/matheusvgc'
                    linkedin='https://www.linkedin.com/in/matheus-viniciusdev/'
                  />

              </div>

          </div>

      </div>

      <Footer />

    </div>
  );
};



export default Weare;
