
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const TermsAndConditions = () => {
  return (
    <div className="app">
      <Header />

      <div className="grow px-8 md:px-20 3xl:px-40 text-justify leading-5">
        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6'>
          TERMOS E CONDIÇÕES DE USO DO BUDD
        </h2>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        Última Atualização: 18 de Janeiro de 2024. <br/>
          Bem vindo(a)! Obrigado por utilizar o <span className='text-green'>BUDD</span>! <br/>
          Esta aplicação e seu conteúdo (“<span className='text-green'>BUDD</span>”) são controlado e operados 
          por Ricardo Silva. 
          – Todos os direitos reservados.
          Estes termos de uso têm por objeto definir as regras a serem seguidas para a utilização do BUDD, sem prejuízo da aplicação da legislação vigente. 
          <br/>
          <br/>
          AO UTILIZAR O <span className='text-green'>BUDD</span>, VOCÊ AUTOMATICAMENTE CONCORDA COM ESTES TERMOS 
          DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NO BUDD OU EM SERVIÇOS A ELE RELACIONADOS. CASO VOCÊ NÃO CONCORDE COMQUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR BUDD. VOCÊ TAMBÉM CONCORDA COM OSTERMOS DESCRITOS EM NOSSA POLÍTICA DE PRIVACIDADE. 
          <br/>
          <br/>
          Caso queira nos dar algum feedback sobre o BUDD, tenha dúvidas ou precise tratar de qualquer assunto relacionado 
          a estes Termos de Uso, entre em contato conosco através do e-mail: aplicationbudd@gmail.com.
       </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>1. O que é o <span className='text-green'>BUDD</span> ?</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
            1.1. Serviços. Somos uma plataforma dedicada a mapear e divulgar bares e eventos de acordo com 
            as preferências, localização, perfil e hábitos de consumo dos usuários. 
            Nosso objetivo é conectar pessoas a bares e eventos noturnos relevantes, facilitando a obtenção de 
            informações, compra de ingressos e produtos. 
            A visualização e interação com bares e eventos por meio de uma interface intuitiva e social. 
        <br/>
        <br/>
            1.2. Suspensão. Nós nos reservamos o direito de suspender ou cancelar, 
            a qualquer momento, o seu acesso à aplicação em caso de suspeita de fraude, 
            obtenção de benefício ou vantagem de forma ilícita, ou pelo não 
            cumprimento de quaisquer condições previstas nestes Termos de Uso, 
            na Política de Privacidade ou na legislação aplicável. Nesses casos, 
            não será devida qualquer indenização a você, e o budd poderá promover 
            a competente ação de regresso, se necessário, bem como tomar quaisquer 
            outras medidas necessárias para buscar e resguardar seus interesses ou recursos. 
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>2. Definições</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
            2.1. Abaixo seguem definições importantes para o pleno entendimento destes Termos de Uso.
        <br/>

                a) <span className='text-green'>Budd</span>: Aplicativo desenvolvido para mapear, organizar e divulgar  bares e eventos.
                <br/>
                b) Usuário: Pessoa física ou jurídica que utiliza os serviços do budd.
                <br/>
                c) Criador de Eventos ou Dono/Gestor de Bar : Usuário que cria e divulga eventos ou bar na plataforma.
                <br/>
                d) Consumidor de Eventos: Usuário que participa de eventos divulgados no Budd.
                <br/>
                e) Evento Aberto ou Bar aberto: Evento ou Bar visível a todos os usuários da plataforma.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>3. Como acesso o BUDD ? </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        3.1. Acesso. Para acessar o <span className='text-green'>BUDD</span> e utilizar suas 
        funcionalidades não é necessário efetuar um cadastro, mas para comprar ingressos e produtos sim. 
        Para cadastrar-se, você nos fornecerá informações pessoais, conforme descrito em nossa Política
         de Privacidade. Para saber mais sobre a privacidade de suas informações pessoais no  <span className='text-green'>BUDD</span>,
          acesse nossa Política de Privacidade. 
          <br/>
          <br/>
          3.2. Idade de Utilização. Para utilizar o <span className='text-green'>BUDD</span>, você deve ter pelo menos 
          18 (dezoito) anos.
          <br/>
          <br/>
          3.3. Titularidade. A partir do cadastro, 
          você será titular de uma conta que somente poderá ser acessada por você. Caso o <span className='text-green'>BUDD</span> detecte alguma conta feita a partir de informações falsas, por usuários que, por exemplo, 
           não possuem a idade mínima permitida, essa conta será automaticamente deletada.
           <br/>
           <br/>
          3.4. Atualização das Informações. Desde já, você se compromete a manter as suas 
          informações pessoais atualizadas. Você também concorda que irá manter o 
          seu login e senha seguros e fora do alcance de terceiros, e não 
          permitirá que a sua conta no <span className='text-green'>BUDD</span> seja usada por outras pessoas. 
          Dessa forma, o usuário responsabiliza-se por todas as ações realizadas em sua conta. 
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>4. A relação contratua entre o <span className='text-green'>BUDD</span> e o usuário</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
            4.1. Relação Contratual. A relação contratual entre o <span className='text-green'>BUDD</span> 
            e o usuário é de prestação de serviços, onde o <span className='text-green'>BUDD</span> fornece 
            uma plataforma digital para mapeamento, divulgação e gerenciamento de eventos, 
            enquanto o usuário concorda em utilizara plataforma de acordo com os Termos de Uso, 
            respeitando as regras e responsabilidades estabelecidas, incluindo o uso lícito e respeitoso da plataforma.  
            <br/>
            <br/>
            4.2. Da quebra contratual. Em caso de descumprimento das funções de boa-fé e probidade 
            desta relação entre o <span className='text-green'>BUDD</span> e o usuário, o <span className='text-green'>BUDD</span> pode tomar as seguintes ações: 
            <br/>
            <br/>
            a) Suspensão de Conta: Suspender temporariamente a conta do usuário até que a questão seja resolvida.
            <br/>
            b) Banimento: Banir permanentemente o usuário da plataforma.
            <br/>
            c) Notificação às Autoridades: Informar às autoridades competentes, se a quebra de confiança envolver atividades ilegais.
            <br/>
            d) Remoção de Eventos: Remover qualquer evento que viole os Termos de Uso.
            <br/>
            e) Ações Legais: Tomar medidas legais apropriadas para proteger os interesses da plataforma e de outros usuários. 
            <br/>
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>5. Funcionalidades <span className='text-green'>BUDD</span> </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        5.1. Divulgação de Eventos. O <span className='text-green'>BUDD</span> permite a 
        divulgação de eventos com base em geolocalização e preferências dos usuários. 
        <br/>
        <br/>
          5.2. Busca de Eventos. A ferramenta de busca avançada do <span className='text-green'> BUDD </span> 
          permite aos usuários localizar eventos relevantes de forma rápida e eficiente, 
          utilizando filtros específicos como palavra-chave, tags, localização, 
          data e categoria, garantindo que os eventos encontrados correspondam 
          exatamente às suas preferências e necessidades. 
        <br/>
        <br/>
          5.3. Perfil do Usuário. Cada usuário possui um perfil personalizado 
          que inclui biografia, contatos, conexões, agenda de eventos e um 
          radar de “analytics”. Além disso, os usuários podem especificar suas 
          preferências e interesses, permitindo que a plataforma sugira eventos 
          que melhor correspondam aos seus gostos e necessidades.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>‍6. Responsabilidade dos usuários </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        6.1. Consumidores de Bares e Eventos. Para o uso da plataforma apenas 
        como consumidores de bares e eventos, o usuário terá as seguintes 
        responsabilidades no uso da nossa plataforma:
        <br/>
        a) Verificar a veracidade das informações do bar ou eventos antes de participar;
        <br/>
        b) Conferir classificação etária do bar ou evento;
        <br/>
        c) Consultar e confirmar horários, disponibilidade de público e local do bar ou evento;
        <br/>
        d) Respeitar as limitações de uso conforme região, localidade e idade.  
        <br/>
        <br/>
        6.2. Criadores de Eventos. A criação e divulgação dos bares ou eventos são de total responsabilidade do usuário. 
        O usuário que criar eventos será responsável por:
        <br/>
        <br/>
        a) A inclusão das informações completas e corretas sobre o evento, incluindo classificação etária.
        <br/>
        b) O <span className='text-green'> BUDD </span> não se responsabiliza por eventos ou bares que infrinjam leis ou normas.
        <br/>
        c) Eventos privados só serão exibidos para convidados e não poderão ser compartilhados com terceiros.
        <br/>
        d) Indicar canais de compras para eventos pagos. O <span className='text-green'> BUDD </span> por enquanto não vende, nem administra ingressos e produtos,
         inclusive os eventualmente negociados.  
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>7. Responsabilidade do usuário e condições de uso</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          <br/>
          
        7.1. Proibições Gerais. É estritamente proibido utilizar o <span className='text-green'> BUDD </span> para atividades ilícitas, 
        incluindo, mas não se limitando  <br/>
        a: (i) venda de entorpecentes e/ou produtos ilícitos;
        (ii) comércio de produtos ilegais; 
        (iii) divulgação de bares ou eventos que promovam atividades criminosas. 
        <br/>
        <br/>
7.2. Consequências. Além das medidas já descritas nestes Termos de Usos, a violação dessas regras resultará em medidas severas, incluindo suspensão ou banimento total da plataforma e notificação às autoridades competentes.  
<br/>
<br/>
7.3. Manutenção do cadastro atualizado. É obrigação do USUÁRIO informar ao <span className='text-green'> BUDD </span>, 
sempre que solicitado, e manter atualizados todos os seus dados cadastrais, 
incluindo, mas não se limitando a:(i) nome completo; (ii) data de nascimento; (iii) número de inscrição no CPF;  (iv) endereço residencial; (v) número do telefone;  (vi) email;  e (vii) cartão de crédito e débito. 
<br/> <br/>
7.4. Uso correto do <span className='text-green'> BUDD </span>. As interações no <span className='text-green'> BUDD </span> devem ser 
respeitosas e corteses, sendo proibido o uso de linguagem ofensiva, discriminatória ou abusiva. 
O <span className='text-green'> BUDD </span> não pode ser utilizado para planejar, 
cometer ou incitar qualquer tipo de crime. 
Qualquer violação dessas regras resultará em ações corretivas, 
que podem incluir a suspensão ou banimento da plataforma e processo judicial. 
<br/> <br/>
7.5. Responsabilização por Eventuais Danos. O <span className='text-green'> BUDD </span>, 
seu controlador, suas afiliadas, parceiras ou funcionários não serão, 
em hipótese alguma, responsabilizados por danos diretos ou indiretos que resultem de, 
ou que tenham relação com o acesso, uso ou a incapacidade de acessar ou utilizar o <span className='text-green'> BUDD </span>. 
<br/> <br/>
7.6. Não-Responsabilização. Tendo em vista as características inerentes ao 
ambiente da internet, o <span className='text-green'> BUDD </span> não se responsabiliza 
por interrupções ou suspensões de conexão, transmissões de computador 
incompletas ou que falhem, bem como por falha técnica de qualquer tipo, incluindo, 
mas não se limitando, ao mau funcionamento eletrônico de qualquer rede,
 hardware ou software. a indisponibilidade de acesso à internet ou ao <span className='text-green'> BUDD </span>, 
 assim como qualquer informação incorreta ou incompleta sobre o <span className='text-green'> BUDD </span> e 
 qualquer falha humana, técnica ou de qualquer outro tipo no processamento das informações do <span className='text-green'> BUDD </span>
  não serão consideradas responsabilidade do <span className='text-green'> BUDD </span>. O <span className='text-green'> BUDD </span> 
  se exime de qualquer responsabilidade proveniente de tais fatos e/ou atos.  
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>8. Quais são os direitos do BUDD sobre a aplicação? </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        8.1. Nossos Direitos. Todos os direitos relativos ao <span className='text-green'> BUDD </span> e suas 
        funcionalidades são de propriedade exclusiva de Ricardo Silva, inclusive no que diz respeito aos seus 
        textos, imagens, layouts, software, códigos, bases dedados, gráficos, artigos, 
        fotografias e demais conteúdos produzidos direta ou indiretamente pelo <span className='text-green'> BUDD </span> 
        (“Conteúdo do <span className='text-green'> BUDD </span>”). O Conteúdo do <span className='text-green'> BUDD </span> é 
        protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, 
        copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, 
        fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo 
        do <span className='text-green'> BUDD </span>, para qualquer finalidade, sem o consentimento 
        prévio e expresso da Cia e seus sócios. Qualquer uso não autorizado do Conteúdo do <span className='text-green'> BUDD </span>
         será considerado como violação dos direitos autorais e de propriedade intelectual do <span className='text-green'> BUDD </span>.  
       
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>9. Direitos dos usuários</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        9.1. Acesso e Controle. Os usuários têm o direito de acessar, 
        corrigir e excluir suas informações pessoais armazenadas no <span className='text-green'> BUDD </span>. 
        <br/>
        <br/>

        9.2. Suporte e Reclamações. O <span className='text-green'> BUDD </span> oferece suporte
         técnico através do Serviço de atendimento ao usuário, disponível no aplicativo. Para contestação 
         de transações ou reclamações, os usuários devem entrar em contato com o Serviço de atendimento 
         ao usuário, que pode ser acessado através do e-mail: aplicationbudd@gmail.com. 
         <br/>
         <br/>
9.3. Serviço de atendimento ao usuário. Ao Usuário é disponibilizada central de atendimento 
ao usuário observando as regras de atendimento descritas neste Termos de Uso e a legislação 
pertinente, para possibilitar a comunicação e denúncias, bem como a obtenção de outras 
informações necessárias, podendo o  <span className='text-green'> BUDD </span>, mediante 
prévio aviso, gravar as ligações telefônicas e registros de mensagens via WhatsApp ou e-mail, 
observada a legislação pertinente.
<br/>
<br/>
 9.4. Garantia ao atendimento das demandas. O <span className='text-green'> BUDD </span> sempre dará 
 retorno aos atendimentos referente às contestações e demais demandas comunicadas pelo 
 usuário através da central de atendimento ao usuário. 
        </p>
       
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>10. Como o <span className='text-green'> BUDD </span> lida com o conteúdo que você e outros usuários produzem ?</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        10.1. Criação de Conteúdo. O <span className='text-green'> BUDD </span> poderá, a seu exclusivo critério, 
        permitir que você ou qualquer outro usuário apresente,carregue, publique ou disponibilize, 
        na aplicação, conteúdo ou informações de texto, imagem, áudio ou vídeo (“Conteúdode Usuário”). 
        <br/>
        <br/>
10.2. Conteúdos Proibidos. É proibido qualquer Conteúdo de Usuário de caráter difamatório, 
calunioso, injurioso, violento, pornográfico, obsceno, ofensivo ou ilícito, conforme apuração do <span className='text-green'> BUDD </span> a 
seu critério exclusivo, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas 
(ex. direito de autor, marcas), sem a expressa autorização do titular desses direitos, cuja violação não será de 
responsabilidade do <span className='text-green'> BUDD </span>. 
<br/>
<br/>
10.3.  Titularidade do Conteúdo. Qualquer Conteúdo de Usuário fornecido por você 
permanece de sua propriedade. Contudo, ao fornecê-lo para o <span className='text-green'> BUDD </span>, 
você nos outorga uma licença em nível mundial, por prazo indeterminado, gratuita e transferível, 
e com direito a sublicenciar, usar, copiar, modificar, criar obras derivadas, distribuir, 
publicar, exibir esse Conteúdo de Usuário em todos os formatos e canais de distribuição possíveis, 
sem necessidade de novo aviso a você, e sem necessidade de qualquer pagamento, desde que isso 
esteja relacionado ao funcionamento da plataforma.  
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>11. O que mais preciso saber sobre esses termos de uso ? </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        11.1. Possibilidade de interrupção por problemas técnicos. O <span className='text-green'> BUDD </span> não 
        garante que seus serviços permanecerão sem interrupção, nem se responsabiliza por 
        eventuais transações que deixem de ser realizadas durante os períodos de indisponibilidade. 
        O usuário reconhece e declara estar ciente de que poderá haver interrupções no 
        fornecimento dos serviços por motivos técnicos, em razão de manutenção preventiva 
        ou corretiva ou por motivos de caso fortuito ou força maior. 
<br/>
<br/>
11.2. Alterações. Para melhorar sua experiência, o <span className='text-green'> BUDD </span> está 
sempre sendo atualizado. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, 
afim de refletir os ajustes realizados. No entanto, sempre que ocorrer qualquer modificação, você 
será previamente informando pelo endereço de e-mail fornecido por você no momento do cadastro ou 
por um aviso em destaque na aplicação. Caso você não concorde com os novos Termos de Uso, 
você poderá rejeitá-los, mas, infelizmente, isso significa que você não poderá mais ter acesso e fazer uso do 
<span className='text-green'> BUDD </span>. Se de qualquer maneira você utilizar o <span className='text-green'> BUDD </span> 
mesmo após a alteração destes Termos de Uso, isso significa que você concorda com todas as modificações. 
<br/>
<br/>
11.3. Não temos bares ou realizamos eventos. O <span className='text-green'> BUDD </span> é uma plataforma que se 
limita a mapear e divulgar bares e eventos criados pelos próprios usuários. Portanto, o <span className='text-green'> BUDD </span> 
não se responsabiliza pelo conteúdo, veracidade, qualidade, segurança ou legalidade dos bares e eventos criados,
compartilhados ou acessados por meio da plataforma. Da mesma forma, o <span className='text-green'> BUDD </span> não 
é responsável pela disponibilização de ingressos ou produtos, realização, cancelamento ou qualquer questão logística 
relacionada aos bares e eventos. Toda a responsabilidade por essas atividades recai exclusivamente 
sobre os donos dos bares, criadores e organizadoresdos eventos. Qualquer problema ou disputa deve 
ser resolvido diretamente entre os usuários envolvidos. 
<br/>
<br/>
11.4. Conflito entre Disposições. Em caso de conflito entre estes termos e os termos modificador, 
os termos posteriores prevalecerão com relação a esse conflito. 
<br/>
<br/>
11.5. Lei e Foro. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. 
Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pelo <span className='text-green'> BUDD </span> 
e, caso persistam, deverão ser solucionadas pelo Foro da Comarca de São luís, Maranhão, com exclusão de qualquer outro, 
por mais privilegiado que seja ou venha a ser. 
<br/>
<br/>
11.6. Dúvidas. Caso você tenha alguma dúvida, comentário ou sugestão, 
por favor, entre em contato conosco por meio do e-mail  
<span className='text-green'> buddaplication@gmail.com</span><br />
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>12. Contatio e dúvidas</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Se você tiver qualquer dúvida sobre estes Termos e Condições, entre em contato conosco: <br />
          E-mail: <span className='text-green'>buddaplication@gmail.com</span><br />
          Telefone/WhatsApp: <span className='text-green'>+55 (98) 98404-7409</span>
        </p>
        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6 mt-10'>
          POLÍTICA DE PRIVACIDADE BUDD
        </h2>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>1. Introdução</h3>
            <p className='mt-2 text-lg md:text-xl font-medium'>
            O <span className='text-green'>BUDD</span> valoriza a privacidade 
            de seus usuários e está comprometida com a proteção de seus dados pessoais, 
            em conformidade com a Lei Geral de Proteção de Dados (Lei n. 13.709/19 - LGPD) e 
            o Marco Civil da Internet (Lei n. 12.965/14). Esta política descreve de forma clara 
            como coletamos, usamos, armazenamos e compartilhamos informações pessoais, garantindo 
            transparência e segurança para todos os usuários da plataforma.
            <p/>
  <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>2. Dados Coletados </h3>
              <p className='mt-2 text-lg md:text-xl font-medium'>
              O BUDD coleta informações essenciais para personalizar a 
              experiência do usuário, garantir a segurança das transações 
              e aprimorar a qualidade dos serviços oferecidos. A coleta de 
              dados ocorre de duas formas:

Diretamente, quando o usuário fornece informações ao se cadastrar, realizar 
uma compra ou interagir com o aplicativo.
Indiretamente, por meio de tecnologias automatizadas que registram 
informações sobre o uso do app e o comportamento do usuário.
Todas as informações coletadas são tratadas de acordo com as melhores 
práticas de segurança da informação e em conformidade com a Lei Geral de
 Proteção de Dados (LGPD).


              <br/>
              <br/>
              • 2.1. Dados de Cadastro: No momento do registro ou durante o uso 
              do aplicativo, o BUDD coleta informações básicas de identificação 
              pessoal para autenticação e criação da conta do usuário. Esses 
              dados incluem:

Nome Completo: Utilizado para identificar o usuário na plataforma e em 
processos de suporte ao cliente.
Endereço de E-mail: Necessário para comunicação com o usuário, envio de 
confirmações de pedidos, atualizações sobre o serviço e recuperação de senha.
 Importante: O BUDD não armazena dados de cartão de crédito ou débito.
Os pagamentos realizados no aplicativo são processados com total segurança 
através da plataforma Stripe, um dos gateways de pagamento mais confiáveis do
 mercado.

Durante o processamento da transação, apenas identificadores de transações e
 tokens de pagamento criptografados são armazenados em nossos servidores para 
 fins de registro financeiro, auditoria de segurança e facilidade em futuras 
 compras.


              <br/>
              <br/>
                • 2.2. Dados de Geolocalização: O <span className='text-green'> BUDD </span> 
                coleta a localização exata do dispositivo do usuário para oferecer 
                funcionalidades baseadas em geolocalização, como:
Exibição de bares e eventos próximos;
Sugestões personalizadas com base na proximidade geográfica.
Detalhes da Coleta:

A coleta de localização é feita somente com o consentimento 
explícito do usuário.
O aplicativo utiliza as permissões do sistema operacional para acessar 
a localização via GPS, Wi-Fi, torres de celular e outros sensores 
do dispositivo.
A localização é coletada apenas em primeiro plano, ou seja, enquanto 
o app está em uso ativo.
O BUDD não realiza o rastreamento contínuo da localização em 
segundo plano.
Essas informações são utilizadas exclusivamente para personalização 
da experiência do usuário, segurança, melhoria da precisão dos serviços e recomendações 
contextuais.
              <br/>
              <br/>
                • 2.3. Informações de Autenticação de Terceiros: 
                Para facilitar o processo de login e proporcionar uma experiência 
                de acesso mais rápida e segura, o BUDD permite que o usuário se
                 autentique usando serviços de terceiros, como Google, Apple e 
                 outros provedores de identidade.

Quando o usuário opta por esse tipo de autenticação, 
coletamos automaticamente:

ID do Usuário (User ID): Um identificador exclusivo gerado pelo provedor 
de autenticação (ex: Google ID, Apple ID), que permite associar a conta do 
usuário ao BUDD sem a necessidade de criar uma nova senha.
Token de Autenticação: Um token seguro usado para validar a sessão do 
usuário de forma criptografada.
Nome e E-mail Vinculados à Conta de Terceiros: Apenas se o provedor de 
login compartilhar essas informações com o BUDD, sempre com o 
consentimento do usuário.
 Importante: O BUDD não armazena senhas dos serviços de terceiros, 
 e o acesso a essas informações é protegido por protocolos de segurança 
 e criptografia robustos.


                <br/>
                <br/>
                • 2.4. Dados de Navegação e Interação: 
                O BUDD coleta automaticamente informações relacionadas ao 
                uso do aplicativo para melhorar o desempenho, garantir a 
                segurança e analisar padrões de comportamento dos usuários. 
                Esses dados incluem:
Endereço IP: Identifica a origem da conexão do usuário para fins de segurança,
prevenção de fraudes e otimização de desempenho.
Tipo de Dispositivo: Inclui informações sobre o modelo do smartphone, sistema 
operacional, versão do aplicativo e configurações do dispositivo.
Informações do Navegador: Para acessos via web, coletamos informações sobre 
o navegador utilizado, como versão, idioma e compatibilidade.
Páginas Acessadas: Registro das telas ou seções do aplicativo visitadas pelo 
usuário para entender quais funcionalidades são mais utilizadas.
Interações no Aplicativo: Dados sobre cliques, tempo de permanência em cada 
tela, rolagens de página e comportamento de navegação, que ajudam a otimizar a 
experiência do usuário.
Dados de Desempenho: Inclui logs de erros, tempo de carregamento das páginas 
e informações sobre falhas técnicas para aprimorar a estabilidade da plataforma.
Essas informações são essenciais para diagnosticar problemas técnicos, 
identificar tentativas de acesso não autorizado e melhorar continuamente 
a experiência do usuário.
                <br/>
                <br/>
                • 2.5. Histórico de Compras e Preferências: O 
                <span className='text-green'>BUDD</span> armazena, em 
                seu banco de dados seguro, registros detalhados de transações 
                realizadas pelos usuários, incluindo informações como o 
                ID do pedido, produtos adquiridos, quantidade, valores 
                pagos, data e hora da compra, além de identificadores de 
                transação vinculados ao processamento de pagamentos. Além 
                disso, mantemos o histórico de compras do usuário, que 
                abrange todos os pedidos anteriores realizados na plataforma,
                 permitindo o acompanhamento de suas atividades e facilitando 
                 o acesso a informações de compras passadas. 
                 O <span className='text-green'> BUDD </span> também 
                 coleta e armazena preferências de eventos e bares, com 
                 base nas interações do usuário, como eventos visualizados, 
                 favoritos, frequência de visitas a determinados estabelecimentos, 
                 e tipos de produtos adquiridos. Esses dados são utilizados 
                 para personalização de recomendações, oferecendo sugestões 
                 mais relevantes de eventos, bares e produtos, além de aprimorar 
                 a experiência do usuário na plataforma. O armazenamento 
                 dessas informações tem ainda o objetivo de fornecer suporte 
                 ao cliente, permitindo uma assistência mais rápida e 
                 eficaz em casos de dúvidas ou problemas relacionados a 
                 compras. Todos os dados são protegidos por medidas de 
                 segurança robustas, garantindo sua integridade, 
                 confidencialidade e proteção contra acessos não autorizados.
              </p>
              Compromisso com a Privacidade e Segurança
O BUDD está comprometido em proteger a privacidade dos seus usuários. Todos 
os dados coletados são tratados com o máximo rigor de segurança e transparência,
 seguindo padrões internacionais de proteção de dados. Os usuários podem, a 
 qualquer momento, solicitar o acesso, correção ou exclusão de suas informações
  pessoais, conforme previsto na LGPD.

Para dúvidas sobre o tratamento de dados, entre em contato com nossa equipe de 
suporte ou com o Encarregado de Proteção de Dados (DPO) pelo e-mail: buddaplication@gmail.com
Para solicitar a exclusão da sua conta e dos seus dados pessoais, basta preencher o formulário disponível no link abaixo:
<br/>
<br/>
<a href="https://docs.google.com/forms/d/e/1FAIpQLSfxtVDoOdbVj7PJFufH0ckG2ApSOIbQUt0uFo6u7oyVo0BoGA/viewform" target="_blank">Solicitar Exclusão de Conta</a>

        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>3. 
          Uso dos Dados </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        Os dados coletados são usados para diversos fins, garantindo a operação segura e eficaz da plataforma:
        <br/>
        <br/>

        • 3.1. Fornecimento do Serviço: O <span className='text-green'>BUDD</span>
         utiliza os dados coletados para viabilizar o funcionamento completo da 
         plataforma, permitindo que os usuários acessem e utilizem todos os 
         recursos disponíveis. Isso inclui a exibição de bares e eventos
          próximos, baseada na localização geográfica do usuário, e o 
          acesso a informações detalhadas sobre estabelecimentos, como 
          endereço, horário de funcionamento, produtos disponíveis e promoções. 
          Além disso, os dados possibilitam o gerenciamento de transações de 
          compra, permitindo que o usuário adicione produtos ao carrinho, 
          conclua pagamentos de forma segura e acompanhe o status de 
          seus pedidos em tempo real. O armazenamento de informações de 
          transações e preferências também permite o gerenciamento de contas 
          de usuários, facilitando o acesso a históricos de compras, notificações 
          sobre eventos relevantes e suporte personalizado em casos de dúvidas 
          ou problemas com pedidos.
        <br/>
        <br/>
        • 3.2. Personalização da Experiência: O <span className='text-green'>BUDD</span>
        utiliza informações de localização, histórico de compras, interações no 
        aplicativo e preferências do usuário para oferecer uma experiência altamente
         personalizada. Esses dados permitem que o aplicativo forneça 
         recomendações de bares, eventos e produtos que estejam alinhados aos 
         interesses do usuário, considerando fatores como frequência de visitas 
         a determinados estabelecimentos, tipos de produtos adquiridos e eventos 
         visualizados ou favoritos. Além disso, o <span className='text-green'>BUDD</span>
          utiliza algoritmos para identificar padrões de comportamento e criar 
          sugestões personalizadas de ofertas, promoções e conteúdos relevantes, 
          com o objetivo de tornar a experiência do usuário mais intuitiva, relevante 
          e agradável. O uso desses dados também contribui para a otimização da 
          interface, priorizando a exibição de conteúdos de maior interesse para 
          cada perfil de usuário.
        <br/>
        <br/>
          •3.3. Desenvolvimento de Produtos e Serviços: Embora o <span className='text-green'>BUDD</span>
           não armazene diretamente dados sensíveis de cartões de crédito ou débito, 
           o aplicativo utiliza identificadores de pagamento e tokens criptografados 
           fornecidos pela Stripe, uma plataforma de pagamentos segura e confiável, 
           para realizar o processamento de transações financeiras. Esses identificadores 
           e tokens permitem que as transações sejam concluídas de forma eficiente, 
           sem a necessidade de armazenar informações confidenciais dos usuários, 
           como números de cartão, códigos de segurança ou datas de validade. O  
           <span className='text-green'>BUDD</span>
           armazena apenas dados essenciais para o registro da transação, como o ID
            da transação, status do pagamento, valor da compra e referências de 
            autenticação da Stripe, garantindo a rastreabilidade e o controle 
            financeiro das operações. Todas as transações ocorrem em um ambiente 
            criptografado, assegurando a proteção contra fraudes, o sigilo das 
            informações financeiras e a conformidade com padrões internacionais de 
            segurança de dados.
          <br/>
          <br/>
            • 3.4. Análises e Melhorias:
            O <span className='text-green'> BUDD </span>  realiza a análise de 
            dados de uso da plataforma para identificar oportunidades de 
            melhoria de desempenho e aprimorar a experiência do usuário. Isso 
            inclui o monitoramento de métricas de desempenho do aplicativo, 
            como tempo de carregamento de páginas, frequência de uso, 
            funcionalidades mais acessadas e possíveis erros de sistema. 
            Além disso, o <span className='text-green'> BUDD </span>  analisa 
            o comportamento dos usuários dentro do app, considerando padrões 
            de navegação, interações com estabelecimentos, eventos mais 
            populares e preferências de consumo. Esses insights permitem que 
            a equipe de desenvolvimento otimize recursos, melhore a usabilidade 
            da interface, identifique potenciais falhas de segurança e 
            implemente novas funcionalidades que atendam melhor às 
            necessidades dos usuários. O uso de dados analíticos também 
            contribui para tomadas de decisão estratégicas, baseadas em 
            informações concretas, visando a evolução contínua do serviço. 
            Todas as análises são realizadas de forma a preservar a privacidade 
            e segurança dos dados pessoais, respeitando as diretrizes da LGPD 
            e demais regulamentações aplicáveis.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>4. 
          Compartilhamento de Dados com Terceiros</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        O BUDD adota medidas rigorosas de segurança e privacidade para proteger 
        os dados pessoais de seus usuários, garantindo que qualquer 
        compartilhamento de informações com terceiros ocorra de forma segura, 
        transparente e em conformidade com a Lei Geral de Proteção de Dados 
        (LGPD) e demais legislações aplicáveis. O compartilhamento de dados
         é realizado apenas quando estritamente necessário, com critérios 
         claros e controles rigorosos para assegurar que os direitos dos 
         titulares sejam respeitados.


        <br/>
        <br/>
        • 4.1. Terceiros Autorizados
O BUDD pode compartilhar dados pessoais com terceiros autorizados, incluindo:

Parceiros comerciais que oferecem serviços complementares ou integrados 
à nossa plataforma, como soluções de pagamentos e logística;
Anunciantes e patrocinadores, para fins de personalização de campanhas 
publicitárias, desde que o usuário tenha consentido previamente;
Provedores de serviços de tecnologia, como empresas responsáveis por 
hospedagem de dados, armazenamento em nuvem, análises de desempenho, 
monitoramento de segurança e suporte técnico;
Plataformas de autenticação de terceiros (ex: Google, Apple), quando o 
usuário opta por realizar login através dessas contas.
Todos esses terceiros são contratualmente obrigados a:

Manter a confidencialidade e segurança dos dados compartilhados;
Adotar práticas de segurança compatíveis com os padrões da LGPD, 
incluindo criptografia, controle de acesso e políticas de proteção de dados;
Utilizar os dados exclusivamente para as finalidades definidas pelo BUDD,
 sendo proibido o uso para fins não autorizados ou incompatíveis 
 com os propósitos originais da coleta.

        <br/>
        <br/>
        • 4.2. Finalidades do Compartilhamento
        <br/>
        <br/>
         O compartilhamento de dados pelo 
        BUDD ocorre com finalidades específicas e bem definidas, incluindo:
        <br/>
Execução de serviços essenciais, como o processamento de pagamentos, realizado 
por meio da Stripe, que recebe apenas informações necessárias para concluir
 as transações de forma segura;
Hospedagem de dados em servidores seguros para garantir o desempenho e a 
continuidade da plataforma;
Envio de comunicações ao usuário, como notificações de confirmação de compras, 
atualizações de serviços, promoções e ofertas personalizadas;
Análises de dados para entender o comportamento dos usuários, aprimorar 
recursos da plataforma e desenvolver novos serviços;
Campanhas de marketing e publicidade direcionada, com base nos interesses 
e interações do usuário, sempre respeitando o consentimento prévio e explícito 
quando necessário.
Em todos os casos, o compartilhamento ocorre com base em fundamentos legais e 
em contratos específicos que limitam o uso dos dados exclusivamente às 
finalidades autorizadas.
        <br/>
        <br/>
        • 4.3. Transferência Internacional de Dados
         <br/>
         <br/>
         O BUDD pode realizar a transferência internacional de dados 
         quando necessário para o funcionamento da plataforma, especialmente 
         em casos de:
         <br/>

Hospedagem em servidores de nuvem internacionais (ex: AWS, Google Cloud);
Integrações com provedores de serviços globais, como sistemas de pagamento 
e autenticação.
Para garantir a proteção dos dados transferidos, adotamos as seguintes medidas:
Avaliação do nível de proteção de dados do país de destino, assegurando que 
ele ofereça garantias compatíveis com a LGPD;
Cláusulas contratuais específicas de proteção de dados, que estabelecem 
obrigações de segurança, confidencialidade e restrição de uso por parte do 
destinatário;
Mecanismos de segurança adicionais, como criptografia de ponta a ponta, 
controle de acesso rigoroso e auditorias regulares para monitoramento do 
cumprimento das obrigações contratuais.
O usuário será informado, sempre que aplicável, sobre a transferência 
internacional de seus dados, podendo exercer seus direitos de acesso, 
correção e exclusão dessas informações, conforme previsto na LGPD.

        • 4.4. Proteção de Dados no Compartilhamento: Antes de compartilhar 
        qualquer dado pessoal com terceiros, o BUDD realiza uma avaliação de 
        impacto à proteção de dados (DPIA), que inclui: Análise dos riscos 
        associados ao compartilhamento, considerando a natureza dos dados 
        e o contexto do tratamento;
Avaliação das medidas de segurança adotadas pelo terceiro, incluindo 
políticas de segurança da informação e práticas de governança de dados;
Verificação da conformidade legal do terceiro com a LGPD e outras leis de 
proteção de dados aplicáveis.
Essa abordagem garante que o compartilhamento de dados ocorra de forma 
segura, com riscos minimizados e em respeito aos direitos e liberdades 
fundamentais dos titulares de dados.
        <br/>
        <br/>   
            •4.5. Compartilhamento por Motivos Legais: O <span className='text-green'> BUDD </span> 
            poderá ser legalmente obrigado a compartilhar dados pessoais em circunstâncias específicas, incluindo:

Cumprimento de obrigações legais e regulatórias, como ordens judiciais, solicitações 
de autoridades públicas, fiscais, reguladoras ou órgãos de segurança;
Proteção de direitos legais, tanto do próprio BUDD quanto de seus usuários, 
parceiros ou terceiros, em casos de litígios judiciais, processos administrativos 
ou arbitrais;
Prevenção de fraudes e crimes cibernéticos, colaborando com investigações de 
atividades ilegais, violações de segurança da informação ou uso indevido da 
plataforma.
O compartilhamento de dados para fins legais será sempre realizado com 
transparência, de forma limitada ao estritamente necessário, e seguindo 
protocolos de segurança para proteger a integridade e a confidencialidade 
das informações.

O BUDD está comprometido em garantir que o compartilhamento de dados 
com terceiros ocorra de forma ética, segura e responsável, respeitando 
os direitos dos usuários e promovendo a proteção da privacidade em todas as
 etapas do tratamento de dados.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>5. Segurança dos Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        O <span className='text-green'> BUDD </span> 
        utiliza tecnologias avançadas para garantir a segurança dos dados 
        em todas as fases do seu ciclo de vida, incluindo:

Criptografia de Dados:

Todos os dados sensíveis, como informações de pagamento e transações financeiras, 
são transmitidos de forma criptografada usando o protocolo TLS 
(Transport Layer Security), o que impede a interceptação de informações por 
terceiros não autorizados.
Os tokens de pagamento e identificadores de transação armazenados são 
protegidos com técnicas de criptografia de ponta a ponta, garantindo 
que apenas sistemas autorizados possam acessá-los.
Controle de Acesso Restrito:

O acesso aos dados pessoais é limitado exclusivamente a colaboradores autorizados, 
que possuem credenciais individuais, com base no princípio do menor privilégio 
(acesso concedido apenas quando necessário para o desempenho de suas funções).
O uso de autenticação multifator (MFA) é obrigatório para administradores de
 sistemas e ambientes críticos, dificultando o acesso não autorizado, 
 mesmo em caso de credenciais comprometidas.

Segurança em Servidores e Armazenamento:

Os dados são armazenados em servidores seguros, hospedados em provedores 
de nuvem confiáveis, como AWS (Amazon Web Services), que oferecem infraestrutura 
certificada com padrões internacionais de segurança (ISO 27001, SOC 2, entre outros).
São implementadas firewalls de proteção, sistemas de prevenção contra 
intrusão (IPS/IDS) e políticas rigorosas de segregação de ambientes 
para proteger contra ameaças cibernéticas.
Monitoramento Contínuo de Vulnerabilidades:

O BUDD realiza o monitoramento constante de seus sistemas para identificar
 e corrigir vulnerabilidades de segurança em tempo real.
São conduzidos regularmente testes de invasão (pentests), auditorias de 
segurança e scans automatizados para avaliar a resiliência da plataforma 
contra ataques cibernéticos.
Backup e Recuperação de Dados:

Os dados críticos são protegidos por um robusto sistema de backup 
automatizado, com cópias de segurança armazenadas de forma segura 
e testadas periodicamente para garantir a recuperação rápida em caso 
de falhas, desastres ou incidentes de segurança.
 5.2. Medidas Administrativas e Organizacionais
Além das proteções tecnológicas, o BUDD adota políticas administrativas 
rigorosas para reforçar a segurança da informação:

Política de Segurança da Informação (PSI):

Mantemos uma política interna de segurança que define diretrizes 
claras para o tratamento seguro de dados pessoais, incluindo regras 
sobre o uso de sistemas, dispositivos e práticas de proteção de informações.
Treinamento e Conscientização:

Todos os colaboradores, especialmente aqueles que lidam com 
dados sensíveis, recebem treinamento contínuo em segurança 
da informação, boas práticas de proteção de dados e conscientização 
sobre riscos cibernéticos.
Gestão de Incidentes de Segurança:

O BUDD possui um Plano de Resposta a Incidentes de Segurança 
(PRIS) que estabelece procedimentos rápidos e eficazes para:
Detectar e investigar incidentes de segurança;
Mitigar riscos e minimizar impactos em caso de violações;
Notificar autoridades competentes e usuários afetados, quando 
necessário, em conformidade com a LGPD.
Avaliações de Impacto à Proteção de Dados (DPIA):

Antes da implementação de novos processos ou sistemas que
envolvam o tratamento de dados pessoais, realizamos avaliações 
de impacto para identificar riscos potenciais à privacidade e 
definir medidas de mitigação apropriadas.
 5.3. Proteção Durante a Transferência Internacional de Dados
Em casos de transferência internacional de dados, o BUDD adota 
medidas adicionais de segurança para garantir a proteção das informações, 
incluindo:

Criptografia de dados em trânsito e em repouso;
Acordos contratuais com cláusulas de proteção de dados;
Verificação da conformidade de fornecedores internacionais 
com legislações de privacidade reconhecidas globalmente.
 5.4. Compromisso com a Privacidade e a Segurança
O compromisso do BUDD com a segurança da informação vai além da 
conformidade legal. Buscamos continuamente aprimorar nossos processos, 
tecnologias e políticas para oferecer uma plataforma segura e confiável 
aos nossos usuários.

Reforçamos que, embora adotemos as melhores práticas de segurança, 
nenhum sistema é 100% imune a riscos. Em caso de suspeita de violação 
de dados ou incidentes de segurança, os usuários podem entrar em contato 
com nossa equipe de proteção de dados por meio do e-mail: buddaplication@gmail.com.


        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>6. Direitos dos Titulares de Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        Conforme a LGPD, os usuários têm o direito de:
        <br/>
        <br/>   
        • 6.1. Acessar os Dados: Solicitar cópia dos dados pessoais armazenados pelo <span className='text-green'>BUDD</span>.
        <br/>
        <br/>   
        • 6.2. Corrigir Informações: Solicitar a correção de dados pessoais incompletos, inexatos ou desatualizados.
        <br/>
        <br/>   
        • 6.3. Solicitar Exclusão: Pedir a exclusão dos dados pessoais, exceto quando sua manutenção for 
        necessária para cumprimento de obrigações legais ou legítimas.
        <br/>
        <br/>   
        • 6.4. Revogar Consentimento: Retirar o consentimento fornecido para o tratamento de dados a 
        qualquer momento, respeitando os limites contratuais e legais.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>7. Retenção de Dados </h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        Os dados pessoais serão armazenados pelo período necessário para cumprir as finalidades descritas 
        nesta política ou conforme exigido por lei, respeitando os prazos legais de retenção e eliminação de dados.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>8. Utilização da Plataforma 
        <span className='text-green'> BUDD</span> por menores e incapazes</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        As soluções do <span className='text-green'> BUDD</span> são próprias para utilização de 
        maiores de 18 anos de idade, de modo que não coletamos conscientemente dados de 
        indivíduos que se encontram abaixo dessa faixa etária. Em caso de identificação da 
        coleta de dados de menores de 18 anos, procederemos com a exclusão da conta criada e 
        dos dados fornecidos, mantendo somente aqueles necessários para evitar nova tentativa de cadastro.
        <br/>
        <br/> 
          Usuários menores de 18 anos deverão obter o consentimento expresso de seus pais, 
          tutores ou representantes legais para utilizar a plataforma <span className='text-green'> BUDD</span> e 
          suas funcionalidades, conforme as disposições dos Termos de Uso e da Política de 
          Privacidade. Os pais, tutores ou representantes legais serão plenamente responsáveis 
          também no caso de acesso à plataforma <span className='text-green'> BUDD</span> por parte de crianças 
          e adolescentes, sem a 
          devida obtenção de autorização prévia. Cabe a eles a integral responsabilidade pela 
          fiscalização das atividades e conduta dos respectivos menores sob sua tutela, bem
           como ciência da integralidade dos presentes Termos.
        </p>
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>9. Alterações na Política de Privacidade</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
        O <span className='text-green'> BUDD</span> reserva-se o direito de modificar esta Política de Privacidade a qualquer momento, 
        sendo o usuário informado das alterações por meio de notificação na plataforma ou por e-mail, quando aplicável.
        </p>
        <h3 className="text-green text-2xl md:text-3xl xl:font-bold mb-5 mt-8">
  Uso da IA Integrada
</h3>
<p className='mt-2 text-lg md:text-xl font-medium'>
  • O BUDD disponibiliza recursos de inteligência artificial para auxiliar em sugestões de bares, eventos. <br/>
  • As respostas da IA têm caráter meramente informativo e não substituem uma pesquisa no perfil 
  do Estabelecimento para confirmar os dados. <br/>
  • Você não deve inserir conteúdos ilícitos, difamatórios, discriminatórios 
  ou que violem direitos autorais na IA; o <span className='text-green'> BUDD</span> pode bloquear tais usos. <br/>
  • O BUDD não se responsabiliza pela precisão ou completude das respostas 
  fornecidas pela IA. Qualquer decisão tomada com base em tais respostas é de 
  inteira responsabilidade do usuário.
</p>
<h3 className="text-green text-2xl md:text-3xl xl:font-bold mb-5 mt-8">
  Tratamento de Dados pela IA Integrada
</h3>
<p className='mt-2 text-lg md:text-xl font-medium'>
  • Para fornecer interações avançadas, o <span className='text-green'> BUDD</span> envia suas perguntas ou conteúdos 
  de texto para processamento pela IA, podendo envolver transferência 
  internacional de dados. <br/>
  • Eventualmente, o provedor da IA pode armazenar logs das interações para 
  fins de aperfeiçoamento de seus modelos, conforme suas próprias políticas. <br/>
  • O <span className='text-green'> BUDD</span> envida esforços para proteger e anonimizar os dados enviados, mas 
  você deve evitar inserir dados sensíveis ou pessoais que não deseje compartilhar. <br/>
  • Ao utilizar a IA, você concorda com esse tratamento de dados e reconhece 
  que o <span className='text-green'> BUDD</span> tem sua própria Política de Privacidade, que recomendamos consultar.
</p>
<h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>10. Contato</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Para dúvidas sobre qualquer um destes termos, entre em contato: <br />
          E-mail: <span className='text-green'>aplicationbudd@gmail.com</span><br />
          Telefone/WhatsApp: <span className='text-green'>+55 (98) 98404-7409</span>
        </p>

      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;